<template>
  <Page color="error" title="Fachschaften">
    <template slot="icons">
      <v-btn icon @click="drawer = !drawer"><v-icon>mdi-menu</v-icon></v-btn>
    </template>

    <v-row no-gutters>
      <v-col>
        <router-view></router-view>
      </v-col>
      <v-col cols="auto">
        <v-navigation-drawer app right v-model="drawer">
          <v-list dense>
            <v-list-item class="mb-4">
              <v-text-field
                hide-details
                single-line
                label="Fachschaft suchen"
                v-model="search"
                append-icon="mdi-magnify"
                clearable
              ></v-text-field>
            </v-list-item>

            <v-list-item
              v-for="item in itemsFiltered"
              :key="item.id"
              exact
              :to="{ name: 'DepartmentDetail', params: { id: item.id } }"
            >
              <v-list-item-content>
                <v-list-item-title>{{ item.code }}</v-list-item-title>
                <v-list-item-subtitle>{{
                  item.description
                }}</v-list-item-subtitle></v-list-item-content
              >
              <v-chip small>{{ item.count }}</v-chip>
            </v-list-item>
          </v-list>
        </v-navigation-drawer>
      </v-col>
    </v-row>
  </Page>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "Departments",
  components: {},
  data() {
    return {
      items: [],
      loading: false,
      drawer: true,
      search: "",
    };
  },
  computed: {
    itemsFiltered() {
      return this.items.filter(
        (el) =>
          !this.search ||
          el.description.toLowerCase().includes(this.search.toLowerCase())
      );
    },
  },
  watch: {
    itemsFiltered() {
      if (
        this.itemsFiltered.length == 1 &&
        this.itemsFiltered[0].id != this.$route.params.id
      ) {
        this.$router.push({
          name: "DepartmentDetail",
          params: { id: this.itemsFiltered[0].id },
        });
      }
    },
  },
  methods: {
    async fetchData() {
      this.loading = true;
      this.items = await this.apiList({ resource: "person/department" });
      this.loading = false;
    },
  },
  created() {
    this.fetchData();
  },
});
</script>
